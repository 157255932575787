
<div class="logo-container">
  <div>
    <img src="{{ img }} "    alt=""   height="60px"  width="200px" 
    >
</div>
</div>

<h1 id="title" class="title">サインイン</h1>


<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>ああスナップ！</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let fail of failed" class="alert-message">{{ failed }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>万歳！</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let succes of success" class="alert-message">{{ success }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">電子メールアドレス:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.email"
           #email="ngModel"
           name="email"
           id="input-email"
           pattern=".+@.+\..+"
           placeholder="電子メールアドレス"
           autofocus
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        メールは必須です！
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        電子メールは本物である必要があります!。
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">パスワード：</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           type="password"
           id="input-password"
           placeholder="パスワード"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        パスワードが必要です！
      </p>
      <p class="caption status-danger" *ngIf="(password.errors?.minlength || password.errors?.maxlength)">
        パスワードには次の内容を含める必要があります
        から {{ getConfigValue('forms.validation.password.minLength') }}
        に {{ getConfigValue('forms.validation.password.maxLength') }}
        キャラクター
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">私を覚えてますか</nb-checkbox>
    <!--
    <a class="forgot-password" routerLink="../request-password">Forgot Password?</a>
    -->
  </div>

  <button nbButton
          fullWidth
          status="success"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
          サインイン
  </button>
</form>

<!--
<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  Or connect with:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link"
         [routerLink]="socialLink.link"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
      <a *ngIf="socialLink.url"
         [attr.href]="socialLink.url"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
    </ng-container>
  </div>
</section>

<section class="another-action" aria-label="Register">
  Don't have an account? <a class="text-link" routerLink="../register">Sign Up</a>
</section>
-->
